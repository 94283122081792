import axios from 'axios';

const customAxios = axios.create({
  baseURL: 'https://abibiman-api.onrender.com',
  // You can switch between different base URLs as needed
  // baseURL: "http://localhost:4000/api/v1",
});

const STORAGE_KEY = 'accessToken';

// Request interceptor to add the token to headers
customAxios.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    if (accessToken) {
      // Add the token in the Authorization header
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 or 403 errors
customAxios.interceptors.response.use(
  (response) => response, // If the response is successful, return it
  async (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Log the error to the console
      console.error('Unauthorized or Forbidden error:', error);

      if (window.location.pathname !== '/login') {
        // Remove the access token and user data from storage
        sessionStorage.removeItem(STORAGE_KEY);
        localStorage.removeItem('userInfo');

        // Redirect the user to the login page
        window.location.href = '/login'; // Adjust this path according to your app's routing
      }
    } else {
      // Handle other response errors
      console.error('Response error:', error);
    }
    return Promise.reject(error);
  }
);

export default customAxios;
