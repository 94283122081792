import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
// config
import { paths } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

export default function LoginButton({ sx }) {
  const { authenticated } = useAuthContext();
  return (
    <Button
      component={RouterLink}
      href={paths.auth.jwt.login}
      variant="contained"
      color="primary"
      sx={{ mr: 1, ...sx }}
    >
      {authenticated ? 'Dashboard' : 'Login'}
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
};
