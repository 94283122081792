import { m } from 'framer-motion';
import { useState, useCallback, useEffect } from 'react';
import {
  List,
  Stack,
  Badge,
  Drawer,
  Divider,
  Tooltip,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { collection, query, where, onSnapshot, doc } from 'firebase/firestore';
import { db } from 'src/utils/firebase-config/config';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import customAxios from 'src/utils/customAxios';
import { useAuthContext } from 'src/auth/hooks';
import NotificationItem from './notification-item';

export default function NotificationsPopover() {
  const drawer = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState([]);
  const [primaryCareDoc, setPrimaryCareDoc] = useState([]);
  const isNotifications = notifications.length > 0;

  const fetchPatients = useCallback(async () => {
    // if (!user?.userID) return;
    try {
      if (user?.userID) {
        const res = await customAxios.get(`/primarycare/user/fetchprovider/${user?.userID}`);
        setPrimaryCareDoc(res.data.data);
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  }, [user?.userID]);

  const fetchNotifications = useCallback(async () => {
    try {
      if (user?.userID) {
        const { data } = await customAxios.get(`/notifications`);
        setNotifications(data.items);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, [user?.userID]);

  useEffect(() => {
    fetchNotifications();
    fetchPatients();
  }, [fetchNotifications, fetchPatients]);

  useEffect(() => {
    const { providerID = '' } = primaryCareDoc || {};
    if (!providerID || !user?.userID) return;

    const conversationsRef = collection(db, 'conversations');
    const conversationDocRef = doc(conversationsRef, providerID);
    const messagesRef = collection(conversationDocRef, user?.userID);
    const messagesQuery = query(
      messagesRef,
      where('senderId', '==', providerID),
      where('seen', '==', 'no')
    );

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      // eslint-disable-next-line no-shadow
      const fetchedMessages = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        message: `You have a new message from ${doc.data().title}.${doc.data().name}`,
        type: 'chat',
        title: 'New Message',
        avatarUrl: doc.data().avatarUrl,
      }));

      setNotifications((prev) => [...fetchedMessages, ...prev]);
    });
    // eslint-disable-next-line consistent-return
    return () => unsubscribe();

    // eslint-disable-next-line consistent-return
  }, [primaryCareDoc, user?.userID]);

  const handleMarkAllAsRead = useCallback(async () => {
    try {
      await customAxios.patch(`/notifications/disable/${user?.userID}`);
      fetchNotifications();
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    async (id, type) => {
      try {
        await customAxios.patch(`/notifications/${id}`);
      } catch (error) {
        console.error('Error marking notification as read:', error);
      }

      switch (type) {
        case 'appointment':
          navigate(`/dashboard/appointments`);
          drawer.onFalse();
          break;
        case 'primary-care':
          navigate(`/dashboard/primary-care`);
          drawer.onFalse();

          break;
        case 'lab-order':
          navigate(`/dashboard/my-care/diagnostic/laboratory`);
          drawer.onFalse();
          break;
        case 'chat':
          navigate(`/dashboard/primary-care/chat/${primaryCareDoc?.providerID}`);
          drawer.onFalse();
          break;
        default:
          break;
      }
      fetchNotifications();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawer, fetchNotifications, navigate]
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={notifications.length} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{ sx: { width: 1, maxWidth: 420 } }}
      >
        <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Notifications
          </Typography>

          {isNotifications && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
          {!smUp && (
            <IconButton onClick={drawer.onFalse}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          )}
        </Stack>

        <Divider />

        <Scrollbar>
          <List disablePadding>
            {notifications
              .sort((a, b) => new Date(b.StringDate) - new Date(a.StringDate))
              .map((notification) => (
                <Stack
                  key={notification._id}
                  onClick={() => handleClick(notification._id, notification.type)}
                >
                  <NotificationItem notification={notification} />
                </Stack>
              ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}
