import PropTypes from 'prop-types';
import { useAuthContext } from '../hooks';
import ForbiddenPage from './component/403Illustration';

// ----------------------------------------------------------------------

export default function PackageBasedGuard({ children, protectedPackages, sx }) {
  const { user } = useAuthContext();

  const currentPackage = user?.userPackage?.toLowerCase();

  if (protectedPackages && protectedPackages.includes(currentPackage)) {
    return <ForbiddenPage />;
  }

  return <>{children}</>;
}

PackageBasedGuard.propTypes = {
  children: PropTypes.node,
  protectedPackages: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
};
