import PropTypes from 'prop-types';
// import Header from '../_common/main/header';
import Header from '../_common/header-simple';

// ----------------------------------------------------------------------

export default function SimpleLayout({ children }) {
  return (
    <>
      <Header />

      {children}

      {/* <Footer /> */}
    </>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.node,
};
