import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard, OnboardingGuardDasboard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import PackageBasedGuard from 'src/auth/guard/package-based-guard';
// import { OverviewDiagnosis, OverviewMedicalNotes } from 'src/sections/overview/records/view';
// import AllVitals from '../../pages/dashboard/records/vitals';

// ----------------------------------------------------------------------

// OVERVIEW
export const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewMetricsPage = lazy(() => import('src/pages/dashboard/metrics'));
const OverViewMyCarePage = lazy(() => import('src/pages/dashboard/mycare'));

// APPOINTMENTS
const AppointmentListPage = lazy(() => import('src/pages/dashboard/appointment/list'));
// PRICING
const PricingPage = lazy(() => import('src/pages/pricing'));
// USERS
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));

// PROVIDERS
const ProvidersCollectionPage = lazy(() => import('src/pages/dashboard/providers/collection'));
const ProvidersListPage = lazy(() => import('src/pages/dashboard/providers/list'));
const ProvidersProfilePage = lazy(() => import('src/pages/dashboard/providers/providersProfile'));
const ProvidersSpecialtyCardList = lazy(() => import('src/pages/dashboard/providers/specialty'));
// PRIMARY-CARE
const PrimaryCareListPage = lazy(() => import('src/pages/dashboard/primary-care/list'));
const PrimaryCareChatPage = lazy(() => import('src/pages/dashboard/primary-care/chat'));
const PrimaryCareProfilePage = lazy(() =>
  import('src/pages/dashboard/primary-care/general-profile')
);
const PrimaryCareMainPage = lazy(() => import('src/pages/dashboard/primary-care/main-page'));

// FACILITIES
const PharmaciesMap = lazy(() => import('src/pages/dashboard/facilities/pharmacies'));
const DiagnosticCentersMap = lazy(() =>
  import('src/pages/dashboard/facilities/diagnostic-centers')
);
const HospitalsMap = lazy(() => import('src/pages/dashboard/facilities/hospitals'));
const PharmaciesList = lazy(() => import('src/pages/dashboard/facilities/pharmacies-list'));
const DiagnosticCentersList = lazy(() =>
  import('src/pages/dashboard/facilities/diagnostic-centers-list')
);
const HospitalsList = lazy(() => import('src/pages/dashboard/facilities/hospitals-list'));

// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const AIChatPage = lazy(() => import('src/pages/dashboard/ai-chat'));

// MYCARE
// const VitalsPage = lazy(() => import('src/pages/dashboard/records/vitals'));
const ConditionPage = lazy(() => import('src/sections/mycare/conditions'));
const PreventivePage = lazy(() => import('src/sections/mycare/preventive'));
const MedicationPage = lazy(() => import('src/sections/mycare/medication'));
const InitialAppointmentPage = lazy(() => import('src/sections/mycare/appointment'));
const DevicesPage = lazy(() => import('src/sections/mycare/devices'));
const PrescriptionPage = lazy(() => import('src/sections/mycare/prescription'));
const ViewCondition = lazy(() => import('src/sections/mycare/conditions/viewCondition'));
const ViewPreventiveCondition = lazy(() => import('src/sections/mycare/preventive/viewCondition'));

const LabsPage = lazy(() => import('src/pages/dashboard/labs/labs'));
const GeneralDiagnosticPage = lazy(() => import('src/pages/dashboard/labs/general-diagnostic'));

// HEALTH RECORDS
const VitalsPage = lazy(() => import('src/pages/dashboard/records/vitals'));
const VideoPage = lazy(() => import('src/pages/dashboard/videochat'));

// const OverviewHealthHistoryPage = lazy(() => import('src/pages/dashboard/records/health-records'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <OnboardingGuardDasboard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </OnboardingGuardDasboard>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'metrics', element: <OverviewMetricsPage /> },
      { path: 'pricing', element: <PricingPage /> },
      { path: 'video', element: <VideoPage /> },

      {
        path: 'my-care',
        element: (
          // <PackageBasedGuard>
          <Outlet />
          // </PackageBasedGuard>d
        ),
        children: [
          { element: <OverViewMyCarePage />, index: true },

          {
            path: 'conditions',
            element: (
              <PackageBasedGuard protectedPackages={['free', 'basic', 'standard']}>
                <ConditionPage />
              </PackageBasedGuard>
            ),
          },
          { path: 'preventive', element: <PreventivePage /> },
          { path: 'devices', element: <DevicesPage /> },
          { path: 'vitals', element: <VitalsPage /> },
          { path: 'prescription', element: <PrescriptionPage /> },
          { path: 'medication', element: <MedicationPage /> },
          { path: 'diagnostic/:type', element: <LabsPage /> },
          { path: 'general-diagnostic', element: <GeneralDiagnosticPage /> },
          { path: 'appointment', element: <InitialAppointmentPage /> },
          { path: 'view-condition/:id', element: <ViewCondition /> },
          { path: 'preventive-care/view-condition/:id', element: <ViewPreventiveCondition /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },

      {
        path: 'appointments',
        children: [
          { element: <AppointmentListPage />, index: true },
          { path: 'list', element: <AppointmentListPage /> },
        ],
      },

      {
        path: 'providers',
        children: [
          { element: <ProvidersListPage />, index: true },
          { path: 'provider-categories', element: <ProvidersListPage /> },
          { path: 'specialist/:id', element: <ProvidersProfilePage /> },
          { path: 'providers-collection', element: <ProvidersCollectionPage /> },

          { path: 'specialty/:name', element: <ProvidersSpecialtyCardList /> },
        ],
      },
      {
        path: 'primary-care',
        children: [
          {
            element: (
              <PackageBasedGuard protectedPackages={['free', 'basic', 'standard']}>
                <PrimaryCareMainPage />
              </PackageBasedGuard>
            ),
            index: true,
          },
          {
            path: 'list',
            element: (
              <PackageBasedGuard protectedPackages={['free', 'basic', 'standard']}>
                <PrimaryCareListPage />
              </PackageBasedGuard>
            ),
          },
          {
            path: 'chat',
            element: (
              <PackageBasedGuard>
                <PrimaryCareChatPage />
              </PackageBasedGuard>
            ),
          },
          {
            path: 'profile/:id',
            element: (
              <PackageBasedGuard>
                <PrimaryCareProfilePage />
              </PackageBasedGuard>
            ),
          },
          {
            path: 'chat/:id',
            element: (
              <PackageBasedGuard>
                <ChatPage />
              </PackageBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'facilities',
        children: [
          { element: <HospitalsList />, index: true },
          { path: 'hospitals/:id', element: <HospitalsMap /> },
          { path: 'pharmacies/:id', element: <PharmaciesMap /> },
          { path: 'diagnostic-centers/:id', element: <DiagnosticCentersMap /> },
          { path: 'hospitals-list', element: <HospitalsList /> },
          { path: 'pharmacies-list', element: <PharmaciesList /> },
          { path: 'diagnostic-centers-list', element: <DiagnosticCentersList /> },
        ],
      },

      {
        path: 'ai-chat',
        element: <AIChatPage />,
      },
    ],
  },
];
