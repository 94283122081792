import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import { OnboardingGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

export const HomePage = lazy(() => import('src/pages/home'));
const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const FaqsPage = lazy(() => import('src/pages/faqs'));
const PrivacyPage = lazy(() => import('src/pages/privacy-policy'));
const TermsPage = lazy(() => import('src/pages/terms-condition'));
const AboutPage = lazy(() => import('src/pages/about-us'));
const ContactPage = lazy(() => import('src/pages/contact-us'));
const ProductsPage = lazy(() => import('src/pages/our-products'));
const PricingPage = lazy(() => import('src/pages/pricing'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
const OnboardingPage = lazy(() => import('src/pages/onboarding'));
const MobilePaymentPage = lazy(() => import('src/pages/mobile-payment'));
const MobileSubscriptionPage = lazy(() => import('src/pages/mobile-subscription'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'about-us', element: <AboutPage /> },
      { path: 'contact-us', element: <ContactPage /> },
      { path: 'products', element: <ProductsPage /> },
      { path: 'faqs', element: <FaqsPage /> },
      { path: 'privacy', element: <PrivacyPage /> },
      { path: 'terms-condition', element: <TermsPage /> },

      { path: 'external', children: [{ path: 'payment', element: <MobilePaymentPage /> }] },
      {
        path: 'external',
        children: [{ path: 'subscriptions', element: <MobileSubscriptionPage /> }],
      },
      { path: 'pricing', element: <PricingPage /> },
    ],
  },
  // {
  //   element: (
  //     <SimpleLayout>
  //       <Suspense fallback={<SplashScreen />}>
  //         <Outlet />
  //       </Suspense>
  //     </SimpleLayout>
  //   ),
  //   children: [{ path: 'pricing', element: <PricingPage /> }],
  // },
  {
    element: (
      <OnboardingGuard>
        <SimpleLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </SimpleLayout>
      </OnboardingGuard>
    ),
    children: [{ path: 'onboarding', element: <OnboardingPage /> }],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
