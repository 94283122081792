import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'src/routes/hooks';
import { AuthContext } from 'src/auth/context/jwt';
import { paths } from 'src/routes/paths';
import customAxios from 'src/utils/customAxios';

export default function OnboardingGuard({ children }) {
  const router = useRouter();
  const { user } = useContext(AuthContext) || {};
  const [userData, setUserData] = useState(null);

  // const STORAGE_KEY = 'accessToken';

  // const accessToken = sessionStorage.getItem(STORAGE_KEY);

  const getUser = async () => {
    try {
      if (user?.userID) {
        const {
          data: { data },
        } = await customAxios.get(`https://abibiman-api.onrender.com/users/${user?.userID}`);
        setUserData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userID]);

  const check = useCallback(() => {
    if (userData?.onBoarding === 'pending') {
      router.replace(paths.onboarding);
    }
  }, [userData, router]);

  useEffect(() => {
    if (userData) {
      check();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return <>{children}</>;
}

OnboardingGuard.propTypes = {
  children: PropTypes.node,
};
