'use client';

import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import customAxios from 'src/utils/customAxios';
import { useSnackbar } from 'src/components/snackbar';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

const initialState = {
  user: null,
  userInfo: null,
  loading: true,
  userID: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL':
      return {
        ...state,
        loading: false,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.data,
        userID: action.payload.data.userID,
      };
    case 'REGISTER':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        loading: false,
        user: null,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

const STORAGE_KEY = 'accessToken';
const STORAGE_EMAIL = 'email-temporary';

export function AuthProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const storageToken = sessionStorage.getItem(STORAGE_KEY);
  const initialize = useCallback(async () => {
    try {
      if (storageToken && isValidToken(storageToken)) {
        if (state.userID) {
          setSession(storageToken);

          const response = await customAxios.get(`/users/${state.userID}`, {
            headers: {
              Authorization: `Basic ${storageToken}`,
            },
          });

          const userInfo = response.data.data;

          const data = { ...userInfo, token: storageToken };
          localStorage.setItem('telical-userInfo', JSON.stringify(data));

          dispatch({
            type: 'INITIAL',
            payload: {
              user: userInfo,
            },
          });
        } else {
          const userInfo = JSON.parse(localStorage.getItem('telical-userInfo'));

          dispatch({
            type: 'INITIAL',
            payload: {
              user: userInfo,
            },
          });
        }
      } else {
        setSession(null);
        localStorage.removeItem('telical-userInfo');
        dispatch({ type: 'LOGOUT' });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: 'LOGOUT' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageToken]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(
    async (email, password) => {
      try {
        let data = { email, password };

        const response = await customAxios.post('/users/login', data);
        const { token, firstName, lastName, phoneNumber, userID } = response.data.data;

        data = { ...data, firstName, lastName, phoneNumber, userID, token };
        setSession(token);

        dispatch({
          type: 'LOGIN',
          payload: { data },
        });

        return response;
      } catch (error) {
        enqueueSnackbar('Login error:', { variant: 'error' });
        throw error;
      }
    },
    [enqueueSnackbar]
  );

  const register = useCallback(
    async (email, password, firstName, lastName, phoneNumber) => {
      try {
        const data = { email, password, firstName, lastName, phoneNumber };

        const response = await customAxios.post('/users/register', data);

        const { accessToken, user } = response.data;
        sessionStorage.setItem(STORAGE_KEY, accessToken);
        sessionStorage.setItem(STORAGE_EMAIL, email);

        dispatch({
          type: 'REGISTER',
          payload: { user },
        });

        return response;
      } catch (error) {
        enqueueSnackbar(error.response.data.message || 'An error has occurred', {
          variant: 'error',
        });
        throw error;
      }
    },
    [enqueueSnackbar]
  );

  const logout = useCallback(async () => {
    setSession(null);
    localStorage.removeItem('telical-userInfo');
    dispatch({ type: 'LOGOUT' });
  }, []);

  const userInfo = JSON.parse(localStorage.getItem('telical-userInfo'));
  const updateUser = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      // if (accessToken && isValidToken(accessToken) && state.userID) {
      const response = await customAxios.get(`/users/${userInfo?.userID}`, {
        headers: {
          Authorization: `Basic ${accessToken}`,
        },
      });

      const updatedUser = response.data.data;
      localStorage.setItem('telical-userInfo', JSON.stringify(updatedUser));

      dispatch({
        type: 'UPDATE_USER',
        payload: { user: updatedUser },
      });
      // }
    } catch (error) {
      console.error('Failed to update user:', error);
    }
  }, [userInfo]);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      userInfo: state.userInfo,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      login,
      register,
      logout,
      updateUser, // Expose updateUser
    }),
    [login, logout, register, state.user, state.userInfo, status, updateUser]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
