import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { Button, DialogActions, DialogContent, Typography } from '@mui/material';

import ControlIllustration from 'src/assets/illustrations/control-desk-alt.jpg';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

export default function InitialDialog({ setDialogOpen, setShowDialog }) {
  const router = useRouter();
  const handleClick = () => {
    setShowDialog(false);
    setDialogOpen(false);
    router.push(paths.dashboard.mycare.appointment);
  };

  return (
    <>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          maxWidth: '500px',
        }}
      >
        <Box
          component="img"
          src={ControlIllustration}
          alt="mission"
          sx={{
            mx: 'auto',
            my: '15px',
            width: { xs: '100%', sm: 250 },
            height: { xs: '100%', sm: 250 },
            borderRadius: '50%',
          }}
        />
        <Typography variant="body1" textAlign="center">
          Please proceed to the next slide to book an appointment with one of our custom care
          agents. They will gather additional details to ensure you have a smooth experience on our
          telehealth platform
        </Typography>
      </DialogContent>
      <DialogActions sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        {/* <Button onClick={() => setDialogOpen(false)}>Cancel</Button> */}
        <Button variant="contained" color="primary" onClick={handleClick}>
          Proceed
        </Button>
      </DialogActions>
    </>
  );
}

InitialDialog.propTypes = {
  setDialogOpen: PropTypes.func,
  setShowDialog: PropTypes.func,
};
