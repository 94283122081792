import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react';
//
import App from './App';
import './index.css';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

root.render(
  <HelmetProvider>
    <AgoraRTCProvider client={client}>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </AgoraRTCProvider>
  </HelmetProvider>
);
