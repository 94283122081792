import { m } from 'framer-motion';
// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
// assets
import { Box, Button } from '@mui/material';
import { ForbiddenIllustrationAlt } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
import Image from 'src/components/image/image';

// ----------------------------------------------------------------------

export default function ForbiddenPage() {
  return (
    <Container component={MotionContainer} sx={{ textAlign: 'center', height: '100vh' }}>
      {/* <Stack sx={{ width: '100%', height: '100%', justifyContent: 'center' }}> */}
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Permission Denied
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary', fontWeight: 400 }}>
          Sorry, you don&apos;t have permission to access this page.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Image
          src={ForbiddenIllustrationAlt}
          alt="403"
          sx={{
            width: 500,
            height: 500,
            '@media (max-width: 500px)': {
              width: '100%',
              height: 'auto',
            },
          }}
        />
      </m.div>
      <Box>
        {/* <Button startIcon={<Iconify icon="ion:arrow-back-outline" />} sx={{ margin: '20px' }}>
            Previous Page
          </Button> */}
        <Button variant="contained" color="primary" href="/pricing">
          Upgrade Package
        </Button>
      </Box>
      {/* </Stack> */}
    </Container>
  );
}
